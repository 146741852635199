import React from 'react'

const BlankContent = () => {
  return (
    <div className='blank py-10'>
        This Website is Under Maintenance
    </div>
  )
}

export default BlankContent